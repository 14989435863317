import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { memo } from "react";

type Props = {
  title: string;
  withMenu: boolean;
  withBackButton: boolean;
};

function Header({ title, withMenu, withBackButton }: Props) {
  return (
    <IonHeader>
      <IonToolbar color="primary">
        {withMenu && (
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
        )}
        {withBackButton && (
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        )}
        <IonTitle>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
}

export default memo(Header);
