import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
} from "@ionic/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { AppContext } from "../State";
import { getAllRacs, updateRacByAdminId } from "../api/admin_api";
import Page from "../components/Page";

type RAC = {
  _id: string;
  name: string;
  number: string;
  owner: string;
  short_descr: string;
  long_descr: string;
  impact_number: string;
  impact_units: string;
  rac_date: string;
  status: string;
  rac_file: string;
  token: string;
  cert: string;
};

function RACs() {
  const { user } = useContext(AppContext);
  const [racs, setRacs] = useState([]);
  const history = useHistory();

  const userId = user!.id;
  const token = user!.token;

  const loadAdminRacs = useCallback(async () => {
    const r = await getAllRacs(userId, token);
    setRacs(r);
  }, [userId]);

  useEffect(() => {
    loadAdminRacs();
  }, [loadAdminRacs]);

  const toggleVisibility = useCallback(
    async (rac: RAC) => {
      const status = rac.status === "Visible" ? "Hidden" : "Visible";
      await updateRacByAdminId(userId, rac._id, { status }, token);
      loadAdminRacs();
    },
    [userId, loadAdminRacs]
  );

  const checkVisibility = useCallback(
    (value: string) => value === "Visible" || value === "Deactivated",
    []
  );

  const viewRac = useCallback(
    (racId: string) => {
      history.push(`/app/rac/${racId}`);
    },
    [history]
  );

  if (!!racs.length) {
    return (
      <Page title="RACs" withMenu>
        <IonList id="inbox-list">
          {racs.map((rac: RAC) => (
            <IonCard key={rac._id}>
              <IonCardHeader>
                <IonCardSubtitle>Owner: {rac.owner}</IonCardSubtitle>
                <IonCardTitle>{rac.name}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <IonItem lines="none">
                  <IonLabel>Status: {rac.status}</IonLabel>
                  <IonToggle
                    checked={checkVisibility(rac.status)}
                    onIonChange={(e) => toggleVisibility(rac)}
                  />
                </IonItem>
              </IonCardContent>

              <IonRow className="ion-justify-content-end">
                <IonCol>
                  <IonButton onClick={() => viewRac(rac._id)}>
                    View RAC
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCard>
          ))}
        </IonList>
      </Page>
    );
  } else {
    return (
      <Page title="RACs" withMenu>
        <IonItem routerDirection="none" lines="none" detail={false}>
          <IonLabel>No RACS found.</IonLabel>
        </IonItem>
      </Page>
    );
  }
}

export default RACs;
