import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/react";
import { useCallback, useContext, useEffect, useState } from "react";

import { AppContext } from "../State";
import { approveUser, getAllUnapproved } from "../api/admin_api";
import Page from "../components/Page";

type User = {
  id: string;
  org: string;
  name: string;
  email: string;
  website: string;
};

function UsersWaitlist() {
  const { user } = useContext(AppContext);
  const [alertText, setAlertText] = useState<string>();
  const [alertUser, setAlertUser] = useState<string>();
  const [showAlertBox, setShowAlertBox] = useState(false);
  const [unapproved, setUnapproved] = useState([]);

  const userId = user?.id;
  const token = user?.token;
  const isAdmin = user?.is_admin ?? false;

  const showAlert = useCallback((action: string, name: string) => {
    if (action === "success") {
      setAlertText("User was successfully approved.");
    } else {
      setAlertText("User was not successfully approved.");
    }
    setAlertUser(name);
    setShowAlertBox(true);
  }, []);

  const getUnapproved = useCallback(async () => {
    if (!userId || !token) return;

    const a = await getAllUnapproved(userId, token);

    setUnapproved(a);
  }, [userId]);

  const approve = useCallback(
    async (id: string, userName: string) => {
      if (!isAdmin || !userId || !token) return;

      const usr = await approveUser(id, userId, token);

      if (usr) {
        showAlert("success", userName);
        getUnapproved();
      } else {
        showAlert("failure", userName);
      }
    },
    [isAdmin, userId, showAlert, getUnapproved]
  );

  useEffect(() => {
    getUnapproved();
  }, [getUnapproved]);

  if (!!unapproved.length) {
    return (
      <Page title="Waitlist" withMenu>
        <IonList>
          <IonAlert
            isOpen={showAlertBox}
            onDidDismiss={() => setShowAlertBox(false)}
            header="User Approval"
            subHeader={alertUser}
            message={alertText}
            buttons={["OK"]}
          />
          {unapproved.map((ua: User) => (
            <IonCard key={ua.email}>
              <IonCardHeader>
                <IonCardSubtitle>{ua.org ?? ""}</IonCardSubtitle>
                <IonCardTitle>{ua.name}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <IonItem lines="none">
                  <IonLabel>Email: {ua.email}</IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>Website: {ua.website}</IonLabel>
                </IonItem>

                <IonRow className="ion-justify-content-end">
                  <IonCol>
                    <IonButton onClick={() => approve(ua.id, ua.name)}>
                      Approve
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          ))}
        </IonList>
      </Page>
    );
  }

  return (
    <Page title="Waitlist" withMenu>
      <IonList>
        <IonItem routerDirection="none" lines="full" detail={false}>
          <IonLabel>User waitlist empty.</IonLabel>
        </IonItem>
      </IonList>
    </Page>
  );
}

export default UsersWaitlist;
