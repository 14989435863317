import { User } from "../models/domain";
import { get, noTokenPost, put } from "./api-caller";

export async function signUpUser(data: any) {
  return await noTokenPost("/auth/signup", data);
}

export async function loginUser(ssoId: String) {
  return await noTokenPost("/auth/login", { sso_user_hash: ssoId });
}

export async function getUserById(id: String, token: string): Promise<User> {
  return await get(`/user/${id}`, token);
}

export async function getUserRacsById(id: String, token: string) {
  return await get(`/user/racs/${id}`, token);
}

export async function updateUserById(
  id: String,
  data: Record<string, any>,
  token: string
) {
  return await put(`/user`, data, token);
}

export async function deactivateUserById(id: String, token: string) {
  return await put(`/user/deactivate/${id}`, {}, token);
}
