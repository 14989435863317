import {
  IonButton,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonText,
} from "@ionic/react";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "../State";
import { getRacById } from "../api/rac_api";
import Page from "../components/Page";
import "./racView.css";

type RAC = {
  name: string;
  number: string;
  owner: string;
  short_descr: string;
  long_descr: string;
  impact_number: string;
  impact_units: string;
  rac_date: string;
  status: string;
  rac_file: string;
  token: string;
  cert: string;
  trokt_thumbprint: string;
};

type Params = {
  racId: string;
};

function RACView() {
  const { user } = useContext(AppContext);
  const { racId } = useParams<Params>();
  const token = user!.token;
  const [rac, setRac] = useState<RAC | null>(null);

  useEffect(() => {
    const loadRac = async () => {
      const data = await getRacById(racId, token);
      setRac(data);
    };

    if (!!racId) {
      loadRac();
    }
  }, [racId]);

  const handleViewRAC = useCallback(
    () => window.open(rac?.rac_file, "_blank")?.focus(),
    [rac]
  );

  if (!rac) return null;

  return (
    <Page title="RAC" withBackButton>
      <IonList id="inbox-list">
        <IonItem lines="none">
          <IonGrid>
            <IonRow>
              <IonLabel className="rac-title">RAC Number</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-item">{rac.number}</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-title">Name</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-item">{rac.name}</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-title">Owner</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-item">{rac.owner}</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-title">Last Date Covered</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-item">{rac.rac_date}</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-title">RAC File</IonLabel>
            </IonRow>
            <IonRow>
              <IonButton color="light" expand="block" onClick={handleViewRAC}>
                View RAC
              </IonButton>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-title">Trokt Thumbprint</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-item">{rac.trokt_thumbprint}</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-title">Short Description</IonLabel>
            </IonRow>
            <IonRow>
              <IonText className="rac-item">{rac.short_descr}</IonText>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-title">Full Description</IonLabel>
            </IonRow>
            <IonRow>
              <IonText className="rac-item">{rac.long_descr}</IonText>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-title">Impact</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-item">
                {rac.impact_number} {rac.impact_units}
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-title">Certification</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="rac-item">{rac.cert}</IonLabel>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonList>
    </Page>
  );
}

export default memo(RACView);
