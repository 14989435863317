import {
  InputCustomEvent,
  IonButton,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTextarea,
  TextareaCustomEvent,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { useCallback, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../State";
import { signUpUser } from "../api/user_api";
import Page from "../components/Page";

type InputProps = {
  bio: string | null;
  phone: string | null;
  website: string | null;
  org: string | null;
};

function SignUp() {
  const [present, dismiss] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const { setUser } = useContext(AppContext);

  const history = useHistory();
  const { search } = useLocation();

  const [inputs, setInputs] = useState<InputProps>({
    bio: null,
    phone: null,
    website: null,
    org: null,
  });

  const signUp = useCallback(async () => {
    try {
      const { bio, phone, website, org } = inputs;

      if (!bio || !phone || !website) {
        presentAlert({
          header: "Could not create account",
          message: "All fields required.",
          buttons: ["OK"],
        });
        return;
      }

      present({
        message: "Creating Account...",
        spinner: "dots",
      });

      const query = new URLSearchParams(search);

      const data = {
        name: query.get("name"),
        email: query.get("email"),
        sso_user_hash: query.get("sso"),
        bio: bio.trim(),
        org: org?.trim(),
        phone: phone.trim(),
        website: website.trim(),
      };

      const newUser = await signUpUser(data);

      setUser(newUser[0]);

      dismiss();
      history.replace("/");
    } catch (e) {
      dismiss();
      presentAlert({
        header: "Could not create account",
        message: "Something went wrong. Please try again.",
        buttons: ["OK"],
      });
    }
  }, [history, setUser, present, dismiss, presentAlert, search, inputs]);

  const setValue = useCallback((e: TextareaCustomEvent | InputCustomEvent) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.detail.value!,
    }));
  }, []);

  return (
    <Page title="Sign Up">
      <IonList>
        <IonItem>
          <IonLabel position="floating">
            Brief Bio (less than 500 words)*
          </IonLabel>
          <IonTextarea
            name="bio"
            value={inputs.bio}
            placeholder="Bio details"
            onIonChange={setValue}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Organization</IonLabel>
          <IonInput
            name="org"
            value={inputs.org}
            placeholder="Enter organization"
            onIonChange={setValue}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">
            Phone number where you are reachable within 48 hours*
          </IonLabel>
          <IonInput
            name="phone"
            value={inputs.phone}
            placeholder="Enter phone number"
            onIonChange={setValue}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">
            LinkedIn profile or personal website*
          </IonLabel>
          <IonInput
            name="website"
            value={inputs.website}
            placeholder="Enter website"
            onIonChange={setValue}
          />
        </IonItem>
      </IonList>
      <IonRow>
        <IonCol sizeLg="4" size="2"></IonCol>
        <IonCol sizeLg="3" size="8">
          <IonButton color="light" onClick={signUp} expand="block">
            I Agree, Signup
          </IonButton>
        </IonCol>
        <IonCol sizeLg="4" size="2"></IonCol>
      </IonRow>
    </Page>
  );
}

export default SignUp;
