import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";

import { getUserById } from "./api/user_api";
import { User } from "./models/domain";

type ContextProps = {
  user: User | null;
  loggedIn: boolean;
  setUser: Dispatch<SetStateAction<User | null>>;
  logout: () => void;
};

const AppContext = createContext<ContextProps>({
  user: null,
  loggedIn: false,
  setUser: () => {},
  logout: () => {},
});

type Props = {
  children: ReactNode;
};

function AppContextProvider({ children }: Props) {
  const [user, setUser] = useState<User | null>(null);

  const token = localStorage.getItem("userId");
  const userId = user?.id;
  const userToken = user?.token;
  console.log("user");
  console.log(user);
  const loggedIn = !!userId || !!token;

  const logout = useCallback(() => {
    setUser(null);
    localStorage.removeItem("userId");
  }, []);

  useEffect(() => {
    const loadUser = async (id: string, token: string) => {
      const data = await getUserById(id, token);
      setUser(data);
    };

    if (!!token && !!userToken) {
      loadUser(token, userToken);
    }
  }, [token]);

  useEffect(() => {
    if (!!userId && !token) {
      localStorage.setItem("userId", userId);
    }
  }, [userId, token]);

  const value = { user, loggedIn, setUser, logout };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export { AppContext, AppContextProvider };
