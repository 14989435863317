import {
  IonContent,
  IonInput,
  IonItem,
  IonItemDivider,
  IonList,
  IonPage,
} from "@ionic/react";
import { useState } from "react";

function AdminOptions() {
  const [text, setText] = useState<string>();

  return (
    <IonPage>
      <IonContent>
        <IonList id="inbox-list">
          <IonItemDivider>Logo</IonItemDivider>
          <IonItem>
            <IonInput
              value={text}
              placeholder="Enter Input"
              onIonChange={(e) => setText(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItemDivider>Signup Text</IonItemDivider>
          <IonItem>
            <IonInput
              value={text}
              placeholder="Enter Input"
              onIonChange={(e) => setText(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItemDivider>Certificate Descriptions Link</IonItemDivider>
          <IonItem>
            <IonInput
              value={text}
              placeholder="Enter Input"
              onIonChange={(e) => setText(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItemDivider>List Of [impact_units] Options </IonItemDivider>
          <IonItem>
            <IonInput
              value={text}
              placeholder="Enter Input"
              onIonChange={(e) => setText(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItemDivider>Certification Abbreviations List</IonItemDivider>
          <IonItem>
            <IonInput
              value={text}
              placeholder="Enter Input"
              onIonChange={(e) => setText(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItemDivider>Filter or Sort Checkbox</IonItemDivider>
          <IonItem>
            <IonInput
              value={text}
              placeholder="Enter Input"
              onIonChange={(e) => setText(e.detail.value!)}
            ></IonInput>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
}

export default AdminOptions;
