import {
  IonButton,
  IonCol,
  IonGrid,
  IonImg,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { AppContext } from "../State";
import { searchRacs } from "../api/rac_api";
import Page from "../components/Page";
import racIcon from "../icons/rac_clear.png";
import "./search.css";

type RAC = {
  _id: string;
  name: string;
  owner: string;
  short_descr: string;
  impact_number: string;
  impact_units: string;
  date: string;
};

function Search() {
  const history = useHistory();
  const { user } = useContext(AppContext);
  const token = user && user!.token;
  const [searchText, setSearchText] = useState("");
  const [racs, setRacs] = useState<RAC[]>([]);

  const search = useCallback(async () => {
    if (!searchText || !token) return;

    const rac = await searchRacs(searchText.trim(), token);
    setRacs(rac);
  }, [searchText]);

  const viewRac = useCallback(
    (racId: string) => history.push(`/app/rac/${racId}`),
    [history]
  );

  if (!racs.length) {
    return (
      <Page title="Search" withMenu>
        <IonRow className="search-icon">
          <IonCol size="5"></IonCol>
          <IonCol size="2">
            <IonImg src={racIcon} />
          </IonCol>
          <IonCol size="5"></IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeLg="4" size="2"></IonCol>
          <IonCol sizeLg="6" size="8" className="center-text">
            <h1>What goodness can you support?</h1>
          </IonCol>
          <IonCol sizeLg="4" size="2"></IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeLg="3.5" size="2"></IonCol>
          <IonCol sizeLg="5" size="8">
            <IonItem lines="full" className="search">
              <IonInput
                value={searchText}
                placeholder="Search..."
                onIonChange={(e) => setSearchText(e.detail.value!)}
              ></IonInput>
            </IonItem>
          </IonCol>
          <IonCol sizeLg="3.5" size="2"></IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeLg="4" sizeSm="3"></IonCol>
          <IonCol sizeLg="4" sizeSm="8">
            <IonButton onClick={search} expand="block" disabled={!searchText}>
              Search for RACs
            </IonButton>
          </IonCol>
          <IonCol sizeLg="4" sizeSm="3"></IonCol>
        </IonRow>
      </Page>
    );
  }

  return (
    <Page title="Search" withMenu>
      <IonRow>
        <IonCol sizeLg="10" size="9">
          <IonItem lines="full" className="search">
            <IonInput
              value={searchText}
              placeholder="Search..."
              onIonChange={(e) => setSearchText(e.detail.value!)}
            />
          </IonItem>
        </IonCol>
        <IonCol sizeLg="2" size="3">
          <IonButton onClick={search} expand="block" disabled={!searchText}>
            Search
          </IonButton>
        </IonCol>
      </IonRow>
      <IonItemDivider className="list-header">
        <h4>RACs</h4>
      </IonItemDivider>
      {racs.map((r: RAC, index: number) => (
        <IonItem
          className="search-item"
          key={index}
          lines="none"
          onClick={() => viewRac(r._id)}
        >
          <IonGrid>
            <IonRow>
              <IonLabel color="link" className="search-title">
                <h1>{r.name}</h1>
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonText>{r.short_descr}</IonText>
            </IonRow>
            <IonRow className="search-detail">
              <IonCol size="2">
                <IonLabel color="secondary">
                  {r.impact_number} {r.impact_units}
                </IonLabel>
              </IonCol>
              <IonCol size="6">
                <IonLabel color="secondary">{r.owner}</IonLabel>
              </IonCol>
              <IonCol size="4"></IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      ))}
    </Page>
  );
}

export default Search;
