import { formPost, get, put } from "./api-caller";

export async function createRac(data: FormData, token: string) {
  return await formPost("/rac", data, token);
}

export async function getRacById(id: String, token: string) {
  return await get(`/rac/${id}`, token);
}

export async function searchRacs(term: String, token: string) {
  return await get(`/rac/search/${term}`, token);
}

export async function updateRacById(
  userId: String,
  id: String,
  data: any,
  token: string
) {
  return await put(`/rac/${id}/${userId}`, data, token);
}

export async function getRacsByUserId(id: String, token: string) {
  return await get(`/rac?creator=${id}`, token);
}

export async function getTroktFileById(id: String, token: string) {
  return await get(`/file/${id}`, token);
}

export async function addCertsToRacById(id: String, token: string) {
  return await put(`/rac/addcerts/${id}`, {}, token);
}

export async function deleteCertFromRacById(id: String, token: string) {
  return await put(`/rac/removecerts/${id}`, {}, token);
}

export async function deleteAllCertsFromRacById(id: String, token: string) {
  return await put(`/rac/removeallcerts/${id}`, {}, token);
}

export async function addImpactUnitToRacById(id: String, token: string) {
  return await put(`/rac/addunits/${id}`, {}, token);
}

export async function deleteImpactUnitFromRacById(id: String, token: string) {
  return await put(`/rac/removeunits/${id}`, {}, token);
}

export async function deleteAllUnitFromRacById(id: String, token: string) {
  return await put(`/rac/removeallunits/${id}`, {}, token);
}
