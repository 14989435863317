import {
  IonCol,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";
import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  documentTextOutline,
  documentTextSharp,
  logOutOutline,
  logOutSharp,
  peopleCircleOutline,
  peopleCircleSharp,
  peopleOutline,
  peopleSharp,
  personAddOutline,
  personAddSharp,
  personCircleOutline,
  personCircleSharp,
  searchOutline,
  searchSharp,
} from "ionicons/icons";

import { AppContext } from "../State";
import racIcon from "../icons/rac_clear.png";

type AppPage = {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
};

const APP_PAGES: AppPage[] = [
  {
    title: "Admins",
    url: "/app/admins",
    iosIcon: peopleCircleOutline,
    mdIcon: peopleCircleSharp,
  },
  {
    title: "RACs",
    url: "/app/racs",
    iosIcon: documentTextOutline,
    mdIcon: documentTextSharp,
  },
  {
    title: "Users",
    url: "/app/users",
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
  },
  {
    title: "User Waitlist",
    url: "/app/waitlist",
    iosIcon: personAddOutline,
    mdIcon: personAddSharp,
  },
];

const MAIN_PAGES: AppPage[] = [
  {
    title: "Search",
    url: "/app/search",
    iosIcon: searchOutline,
    mdIcon: searchSharp,
  },
  {
    title: "Profile",
    url: "/app/profile",
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp,
  },
];

function Menu() {
  const { user, logout } = useContext(AppContext);
  const history = useHistory();

  const isAdmin = user?.is_admin ?? false;

  const handleLogout = useCallback(() => {
    logout();
    history.replace("/");
  }, [history, logout]);

  return (
    <IonMenu side="start" contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader className="sidebar-image">
            <IonCol size="4"></IonCol>
            <IonCol size="4">
              <IonImg src={racIcon} />
            </IonCol>
            <IonCol size="4"></IonCol>
          </IonListHeader>
          {MAIN_PAGES.map((page, index) => (
            <IonMenuToggle key={index} autoHide={false}>
              <IonItem
                routerLink={page.url}
                routerDirection="root"
                lines="none"
                button
              >
                <IonIcon slot="start" ios={page.iosIcon} md={page.mdIcon} />
                <IonLabel>{page.title}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          ))}
          {isAdmin && (
            <>
              <IonListHeader>Admin</IonListHeader>
              {APP_PAGES.map((page, index) => (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    routerLink={page.url}
                    routerDirection="root"
                    lines="none"
                    button
                  >
                    <IonIcon slot="start" ios={page.iosIcon} md={page.mdIcon} />
                    <IonLabel>{page.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              ))}
            </>
          )}
          <IonMenuToggle autoHide={false}>
            <IonItem onClick={handleLogout} lines="none" button>
              <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
              <IonLabel>Logout</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
}

export default Menu;
