import {
  IonAlert,
  IonButton,
  IonCol,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToggle,
} from "@ionic/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { businessOutline, businessSharp } from "ionicons/icons";

import { AppContext } from "../State";
import { createRac, updateRacById } from "../api/rac_api";
import { getUserRacsById, updateUserById } from "../api/user_api";
import Page from "../components/Page";
import racIcon from "../icons/profile-img.png";

type RAC = {
  _id: string;
  name: string;
  number: string;
  owner: string;
  short_descr: string;
  long_descr: string;
  impact_number: string;
  impact_units: string;
  rac_date: string;
  status: string;
  rac_file: string;
  token: string;
  cert: string;
};

function checkVisibility(value: string) {
  return value === "Visible";
}

function Profile() {
  const { user, setUser } = useContext(AppContext);

  const [alertText, setAlertText] = useState<string>();
  const [alertHeader, setAlertHeader] = useState<string>();
  const [showAlertBox, setShowAlertBox] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [userRacs, setUserRacs] = useState([]);
  const [addRac, setAddRac] = useState(false);
  const [racName, setRacName] = useState<string>();
  const [racOwner, setRacOwner] = useState<string>();
  const [racShortDesc, setRacShortDesc] = useState<string>();
  const [racLongDesc, setRacLongDesc] = useState<string>();
  const [racImpactNumber, setRacImpactNumber] = useState<string>();
  const [racImpactUnit, setRacImpactUnit] = useState<string>();
  const [racCerts, setRacCerts] = useState<string>();
  const [racDate, setRacDate] = useState<string>();
  const [racFile, setRacFile] = useState<File>();
  const [racFileName, setRacFileName] = useState<string>();
  const [racThumbprint, setRacThumbprint] = useState<string>();
  const history = useHistory();

  const showAlert = useCallback((action: string) => {
    setAlertHeader("User Information");
    if (action === "success") {
      setAlertText("User info saved successfully.");
      setEditUser(false);
    } else {
      setAlertText("User was not saved.");
    }
    setShowAlertBox(true);
  }, []);

  const setUserData = useCallback(
    (value: string, name: string) => {
      if (!user) return;

      setUser({
        ...user,
        [name]: value,
      });
    },
    [user, setUser]
  );

  const save = useCallback(async () => {
    if (!user) return;

    const data = await updateUserById(user.id, user, user.token);

    if (data) {
      showAlert("success");
    } else {
      showAlert("failure");
    }
  }, [user, showAlert]);

  const openRacFile = useCallback(() => {
    document.getElementById("file-input")?.click();
  }, []);

  const loadUserRacs = useCallback(async () => {
    if (!user) return;

    const r = await getUserRacsById(user.id, user.token);
    if (r.racs) {
      setUserRacs(r.racs);
    }
  }, [user]);

  const toggleVisibility = useCallback(
    async (rac: RAC) => {
      if (!user) return;
      const status = rac.status === "Visible" ? "Deactivated" : "Visible";
      await updateRacById(user.id, rac._id, { status }, user.token);
      loadUserRacs();
    },
    [user, loadUserRacs]
  );

  const addRacFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.files) {
      const file = e.target.files[0];
      setRacFile(file);
      setRacFileName(file.name);
    }
  }, []);

  const saveRac = useCallback(async () => {
    if (!user) return;
    if (
      !!racName &&
      !!racOwner &&
      !!racShortDesc &&
      !!racLongDesc &&
      !!racImpactNumber &&
      !!racDate &&
      !!racFile &&
      !!racImpactUnit &&
      !!racThumbprint
    ) {
      const formData = new FormData();

      formData.append("name", racName);
      formData.append("owner", racOwner);
      formData.append("short_descr", racShortDesc);
      formData.append("long_descr", racLongDesc);
      formData.append("impact_number", racImpactNumber);
      formData.append("impact_units", racImpactUnit);
      formData.append("trokt_thumbprint", racThumbprint);
      !!racCerts && formData.append("certs", racCerts);
      !!racCerts && formData.append("cert", racCerts);
      formData.append("rac_date", racDate);
      formData.append("rac_file", racFile);
      formData.append("creator", user.id);
      formData.append("trokt_token", "71E16651-B073-4B71-99E9-E08E70AFAC39");

      await createRac(formData, user.token);
      setAlertHeader("RAC Added");
      setAlertText("RAC added successfully!");
      setShowAlertBox(true);
      setAddRac(false);
      loadUserRacs();
    } else {
      setAlertHeader("RAC Add Failed");
      setAlertText("Required fields missing.");
      setShowAlertBox(true);
    }
  }, [
    user,
    racName,
    racOwner,
    racShortDesc,
    racLongDesc,
    racImpactNumber,
    racDate,
    racFile,
    racImpactUnit,
    racThumbprint,
    racCerts,
    loadUserRacs,
  ]);

  const viewRac = useCallback(
    (racId: string) => history.push(`/app/rac/${racId}`),
    [history]
  );

  useEffect(() => {
    loadUserRacs();
    setRacFileName("No file selected...");
  }, [loadUserRacs]);

  if (!user) {
    return (
      <Page title="Profile" withMenu>
        <p>Loading...</p>
      </Page>
    );
  } else if (!user.is_approved) {
    return (
      <Page title="Profile" withMenu>
        <IonRow>
          <IonCol size="4"></IonCol>
          <IonCol size="4">
            <IonImg src={racIcon} />
          </IonCol>
          <IonCol size="4"></IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeLg="4" size="2"></IonCol>
          <IonCol sizeLg="4" size="8" className="center-text">
            <h1>Account approval pending...</h1>
          </IonCol>
          <IonCol sizeLg="4" size="2"></IonCol>
        </IonRow>
      </Page>
    );
  } else if (editUser) {
    return (
      <Page title="Profile" withMenu>
        <IonList id="inbox-list" className="remove-bottom-padding">
          <IonAlert
            isOpen={showAlertBox}
            onDidDismiss={() => setShowAlertBox(false)}
            cssClass="my-custom-class"
            header={"User Saved"}
            subHeader={""}
            message={alertText}
            buttons={["OK"]}
          />
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">Name</IonLabel>
              </IonRow>
              <IonRow>
                <IonInput
                  className="edit-input"
                  class="ion-input"
                  value={user.name}
                  placeholder="Enter Input"
                  onIonChange={(e) => setUserData(e.detail.value!, "name")}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">Organization</IonLabel>
              </IonRow>
              <IonRow>
                <IonInput
                  className="edit-input"
                  value={user.org}
                  placeholder="Enter Input"
                  onIonChange={(e) => setUserData(e.detail.value!, "org")}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">Bio</IonLabel>
              </IonRow>
              <IonRow>
                <IonInput
                  className="edit-input"
                  value={user.bio}
                  placeholder="Enter Input"
                  onIonChange={(e) => setUserData(e.detail.value!, "bio")}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">Phone</IonLabel>
              </IonRow>
              <IonRow>
                <IonInput
                  className="edit-input"
                  value={user.phone}
                  placeholder="Enter Input"
                  onIonChange={(e) => setUserData(e.detail.value!, "phone")}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">LinkedIn/Website</IonLabel>
              </IonRow>
              <IonRow>
                <IonInput
                  className="edit-input"
                  value={user.website}
                  placeholder="Enter Input"
                  onIonChange={(e) => setUserData(e.detail.value!, "website")}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonList>
        <IonRow>
          <IonCol sizeLg="3" sizeSm="3"></IonCol>
          <IonCol sizeLg="3" sizeSm="8">
            <IonButton
              key="hideEdit"
              color="medium"
              onClick={() => setEditUser(false)}
              expand="block"
            >
              Cancel
            </IonButton>
          </IonCol>
          <IonCol sizeLg="3" sizeSm="8">
            <IonButton color="success" onClick={save} expand="block">
              Save Information
            </IonButton>
          </IonCol>
          <IonCol sizeLg="3" sizeSm="3"></IonCol>
        </IonRow>
      </Page>
    );
  } else if (addRac) {
    return (
      <Page title="Profile" withMenu>
        <IonItemDivider className="list-header">
          <h4>Add RAC</h4>
        </IonItemDivider>
        <IonList id="inbox-list" className="remove-bottom-padding">
          <IonAlert
            isOpen={showAlertBox}
            onDidDismiss={() => setShowAlertBox(false)}
            cssClass="my-custom-class"
            header={alertHeader}
            subHeader={""}
            message={alertText}
            buttons={["OK"]}
          />
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">Name</IonLabel>
              </IonRow>
              <IonRow>
                <IonInput
                  class="edit-input"
                  value={racName}
                  placeholder="Descriptive name, less than eight words"
                  onIonChange={(e) => setRacName(e.detail.value!)}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">Owner</IonLabel>
              </IonRow>
              <IonRow>
                <IonInput
                  class="edit-input"
                  value={racOwner}
                  placeholder="Who owns the RAC?"
                  onIonChange={(e) => setRacOwner(e.detail.value!)}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">Short Description</IonLabel>
              </IonRow>
              <IonRow>
                <IonInput
                  class="edit-input"
                  value={racShortDesc}
                  placeholder="Enter short description, less than 100 words."
                  onIonChange={(e) => setRacShortDesc(e.detail.value!)}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">Long Description</IonLabel>
              </IonRow>
              <IonRow>
                <IonInput
                  class="edit-input"
                  value={racLongDesc}
                  placeholder="Enter full description."
                  onIonChange={(e) => setRacLongDesc(e.detail.value!)}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel className="edit-header">Impact Number</IonLabel>
                </IonCol>
                <IonCol>
                  <IonLabel className="edit-header">Impact Unit</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonInput
                    class="edit-input"
                    value={racImpactNumber}
                    placeholder="Impact number"
                    onIonChange={(e) => setRacImpactNumber(e.detail.value!)}
                  ></IonInput>
                </IonCol>
                <IonCol>
                  <IonSelect
                    value={racImpactUnit}
                    placeholder="Select One"
                    onIonChange={(e) => setRacImpactUnit(e.detail.value!)}
                  >
                    <IonSelectOption value="acres">acres</IonSelectOption>
                    <IonSelectOption value="gallons">gallons</IonSelectOption>
                    <IonSelectOption value="kilograms">
                      kilograms
                    </IonSelectOption>
                    <IonSelectOption value="kilometers">
                      kilometers
                    </IonSelectOption>
                    <IonSelectOption value="liters">liters</IonSelectOption>
                    <IonSelectOption value="miles">miles</IonSelectOption>
                    <IonSelectOption value="mtc02">MC02</IonSelectOption>
                    <IonSelectOption value="pounds">pounds</IonSelectOption>
                    <IonSelectOption value="tons">tons</IonSelectOption>
                  </IonSelect>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">
                  Certification (Option)
                </IonLabel>
              </IonRow>
              <IonRow>
                <IonSelect
                  value={racCerts}
                  placeholder="Select One"
                  onIonChange={(e) => setRacCerts(e.detail.value!)}
                >
                  <IonSelectOption value="LCFS**">
                    (LCFS**) Low-Carbon Fuel Standard
                  </IonSelectOption>
                  <IonSelectOption value="RFS**">
                    (RFS**) Renewable Fuel Standard
                  </IonSelectOption>
                  <IonSelectOption value="ISCC**">
                    (ISCC**) International Sustainability & Carbon Certification
                  </IonSelectOption>
                  <IonSelectOption value="USGBC**">
                    (USGBC**) United States Green Building Council
                  </IonSelectOption>
                  <IonSelectOption value="None">None</IonSelectOption>
                </IonSelect>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">RAC Date</IonLabel>
              </IonRow>
              <IonRow className="edit-input">
                <IonDatetime
                  min="2020-01-01"
                  max="2050-12-31"
                  className="date-time"
                  color="success"
                  value={racDate}
                  onIonChange={(e) => setRacDate(e.detail.value! as string)}
                ></IonDatetime>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">RAC File</IonLabel>
              </IonRow>
              <IonRow>
                <IonButton
                  key="addRac"
                  color="light"
                  onClick={openRacFile}
                  expand="block"
                >
                  Add File
                </IonButton>
                <input
                  className="file-input"
                  id="file-input"
                  type="file"
                  hidden
                  accept=".png,.jpg,.jpeg,.pdf"
                  onChange={addRacFile}
                />
              </IonRow>
              <IonRow>
                <IonLabel className="file-name">{racFileName}</IonLabel>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonLabel className="edit-header">Trokt Thumbprint</IonLabel>
              </IonRow>
              <IonRow>
                <IonInput
                  class="edit-input"
                  value={racThumbprint}
                  placeholder="Enter Input"
                  onIonChange={(e) => setRacThumbprint(e.detail.value!)}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonList>
        <IonRow>
          <IonCol sizeLg="3" sizeSm="3"></IonCol>
          <IonCol sizeLg="3" sizeSm="8">
            <IonButton
              key="hideEdit"
              color="medium"
              onClick={() => setAddRac(false)}
              expand="block"
            >
              Cancel
            </IonButton>
          </IonCol>
          <IonCol sizeLg="3" sizeSm="8">
            <IonButton color="success" onClick={saveRac} expand="block">
              Save RAC
            </IonButton>
          </IonCol>
          <IonCol sizeLg="3" sizeSm="3"></IonCol>
        </IonRow>
      </Page>
    );
  } else {
    return (
      <Page title="Profile" withMenu>
        <IonRow className="profile-image">
          <IonCol sizeXl="1" sizeLg="2" sizeSm="2">
            <IonImg src={racIcon} />
          </IonCol>
          <IonCol sizeXl="1" sizeLg="2" sizeSm="10"></IonCol>
          <IonCol sizeXl="6" sizeLg="5" sizeSm="4"></IonCol>
        </IonRow>
        <IonRow className="profile-image">
          <IonCol sizeXl="1" sizeLg="2" sizeSm="2">
            <IonButton
              key="showEdit"
              color="light"
              onClick={() => setEditUser(true)}
              expand="block"
            >
              Edit
            </IonButton>
          </IonCol>
          <IonCol sizeXl="1" sizeLg="4" sizeSm="10"></IonCol>
          <IonCol sizeXl="6" sizeLg="4" sizeSm="4"></IonCol>
        </IonRow>
        <IonList className="remove-bottom-padding">
          <IonAlert
            isOpen={showAlertBox}
            onDidDismiss={() => setShowAlertBox(false)}
            header={alertHeader}
            subHeader={""}
            message={alertText}
            buttons={["OK"]}
          />

          <IonItem lines="none" className="profile-section">
            <IonGrid className="profile-section">
              <IonRow className="profile-section">
                <IonLabel>
                  {user.name}
                  <IonCol></IonCol>
                  <IonIcon
                    slot="start"
                    ios={businessSharp}
                    md={businessOutline}
                  />
                  {user.org}
                </IonLabel>
              </IonRow>
              <IonRow>
                <IonText>{user.bio}</IonText>
              </IonRow>
              <IonRow>
                <IonLabel>{user.phone}</IonLabel>
              </IonRow>
              <IonRow>
                <IonLabel>{user.website}</IonLabel>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItemDivider className="rac-header">
            <IonCol sizeLg="4" sizeSm="3">
              <h4> RACs</h4>
            </IonCol>
            <IonCol sizeLg="6" sizeSm="3"></IonCol>
            <IonCol sizeLg="2" sizeSm="3">
              <IonButton
                key="addRac"
                color="light"
                onClick={() => setAddRac(true)}
                expand="block"
              >
                Add RAC
              </IonButton>
            </IonCol>
          </IonItemDivider>
          <IonItem
            key="header"
            className="list-header"
            routerDirection="none"
            lines="full"
            detail={false}
          >
            <IonLabel>Name</IonLabel>
          </IonItem>
          {userRacs.map((r: RAC) => {
            return (
              <IonItem
                key={r.number}
                className="view-rac"
                lines="full"
                routerDirection="none"
                detail={false}
              >
                <IonLabel>{r.name}</IonLabel>
                <IonItem lines="none">
                  <IonLabel>{r.status}</IonLabel>
                  {r.status !== "Hidden" && (
                    <IonToggle
                      checked={checkVisibility(r.status)}
                      onIonChange={(e) => toggleVisibility(r)}
                    />
                  )}
                </IonItem>
                <IonButton
                  color="light"
                  expand="block"
                  onClick={() => viewRac(r._id)}
                >
                  View RAC
                </IonButton>
              </IonItem>
            );
          })}
        </IonList>
      </Page>
    );
  }
}

export default Profile;
