import { MsalProvider } from "@azure/msal-react";
import React from "react";
import { createRoot } from "react-dom/client";

import { PublicClientApplication } from "@azure/msal-browser";

import App from "./App";
import { AppContextProvider } from "./State";
import { msalConfig } from "./config/msConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </MsalProvider>
  </React.StrictMode>
);
