import { useMsal } from "@azure/msal-react";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  useGoogleLogin,
} from "@leecheuk/react-google-login";
import { memo, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import { logoGoogle, logoMicrosoft } from "ionicons/icons";

import { AppContext } from "../State";
import { logError } from "../api/error_api";
import { loginUser } from "../api/user_api";
import Page from "../components/Page";
import { loginRequest } from "../config/msConfig";
import racIcon from "../icons/rac_logo_words_green-clear.png";
import { getErrorMessage } from "../utils";

type User = {
  name: string | undefined;
  email: string;
  sso: string;
};

function Login() {
  const [present, dismiss] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const { setUser } = useContext(AppContext);

  const { instance } = useMsal();
  const history = useHistory();

  const handleError = useCallback(
    (error: string) => {
      presentAlert({
        header: "Unable to login",
        message: "Something went wrong. Please try again.",
        buttons: ["OK"],
      });
      logError(error);
    },
    [presentAlert]
  );

  const handleLogin = useCallback(
    async (data: User) => {
      try {
        present({
          message: "Loading Account...",
          spinner: "dots",
        });
        const user = await loginUser(data.sso);

        dismiss();
        console.log(user);
        if (!!user.token) {
          history.push("/app/search");
          setUser(user);
          return;
        }

        history.push(
          `/signup?name=${data.name}&email=${data.email}&sso=${data.sso}`
        );
      } catch (e) {
        dismiss();
        handleError(getErrorMessage(e));
      }
    },
    [history, setUser, present, dismiss, handleError]
  );

  const handleMSLogin = useCallback(async () => {
    try {
      instance.loginPopup(loginRequest).then((t) => {
        if (!t.account) return;

        const data: User = {
          name: t.account.name,
          email: t.account.username,
          sso: `MS_${t.account.homeAccountId}`,
        };

        handleLogin(data);
      });
    } catch (e: any) {
      handleError(getErrorMessage(e));
    }
  }, [instance, handleLogin, handleError]);

  const handleGoogleLoginSuccess = useCallback(
    async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if ("accessToken" in response) {
        const data: User = {
          name: response.profileObj.name,
          email: response.profileObj.email,
          sso: `GO_${response.profileObj.googleId}`,
        };

        await handleLogin(data);
      } else {
        console.log("offline");
      }
    },
    [handleLogin]
  );

  const handleGoogleLoginFailure = useCallback(
    (e: any) => {
      console.log("---ERROR---");
      handleError(getErrorMessage(e));
    },
    [handleError]
  );

  const { signIn } = useGoogleLogin({
    clientId:
      "294348470797-ina938utaitnq1kleov4jjpamvhjvvco.apps.googleusercontent.com",
    cookiePolicy: "single_host_origin",
    onSuccess: handleGoogleLoginSuccess,
    onFailure: handleGoogleLoginFailure,
  });

  return (
    <Page title="Login">
      <IonRow className="ion-margin-top">
        <IonCol sizeMd="4.5" size="4"></IonCol>
        <IonCol sizeMd="3" size="4">
          <IonImg src={racIcon} />
        </IonCol>
        <IonCol sizeMd="4.5" size="4"></IonCol>
      </IonRow>
      <IonGrid>
        <IonRow>
          <IonCol size="2" />
          <IonCol className="ion-text-center" size="8">
            <h3>You must be logged in to search.</h3>
          </IonCol>
          <IonCol size="2" />
        </IonRow>
        <IonRow>
          <IonCol size="1" />
          <IonCol size="10" className="ion-text-center">
            <h4>
              Don't have an account yet? All signups must be approved during our
              Beta phase. Signup below to be added to our waitlist!
            </h4>
          </IonCol>
          <IonCol size="1" />
        </IonRow>
        <IonRow>
          <IonCol />
          <IonCol className="ion-justify-content-center ion-text-center">
            <IonButton color="dark" fill="outline" onClick={signIn}>
              <IonIcon slot="start" icon={logoGoogle} />
              Log in with Google
            </IonButton>
          </IonCol>
          <IonCol />
        </IonRow>
        <IonRow>
          <IonCol />
          <IonCol className="ion-justify-content-center ion-text-center">
            <IonButton color="dark" fill="outline" onClick={handleMSLogin}>
              <IonIcon slot="start" icon={logoMicrosoft} />
              Log in with Microsoft
            </IonButton>
          </IonCol>
          <IonCol />
        </IonRow>
      </IonGrid>
    </Page>
  );
}

export default memo(Login);
