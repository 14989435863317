import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";

import { AppContext } from "../State";
import { getAllApproved } from "../api/admin_api";
import Page from "../components/Page";

type User = {
  org: string;
  name: string;
  email: string;
  website: string;
};

function Users() {
  const { user } = useContext(AppContext);
  const [approved, setApproved] = useState([]);

  const userId = user!.id;
  const token = user!.token;

  useEffect(() => {
    const getApproved = async () => {
      setApproved(await getAllApproved(userId, token));
    };

    getApproved();
  }, [userId]);

  if (!!approved[0]) {
    return (
      <Page title="Users" withMenu>
        <IonList>
          {approved.map((user: User) => {
            return (
              <IonCard key={user.email}>
                <IonCardHeader>
                  <IonCardSubtitle>{user.org ?? ""}</IonCardSubtitle>
                  <IonCardTitle>{user.name}</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonItem lines="none">
                    <IonLabel>Email: {user.email}</IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Website: {user.website}</IonLabel>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            );
          })}
        </IonList>
      </Page>
    );
  }

  return (
    <Page title="Users" withMenu>
      <p>Loading...</p>
    </Page>
  );
}

export default Users;
