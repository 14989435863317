import { get, post, put, remove } from "./api-caller";

export async function getAllRacs(id: String, token: string) {
  return await get(`/admin/findall/racs`, token);
}

export async function getAllAdmins(id: String, token: string) {
  return await get(`/admin/findall/admins`, token);
}

export async function getAllUnapproved(id: String, token: string) {
  return await get(`/admin/findall/unapproved`, token);
}

export async function getAllApproved(id: String, token: string) {
  return await get(`/admin/findall/approved`, token);
}

export async function approveUser(id: String, adminId: String, token: string) {
  return await put(`/admin/action/user/approve/${adminId}/${id}`, {}, token);
}

export async function disapproveUser(id: String, token: string) {
  return await put(`/admin/action/user/disapprove/${id}`, {}, token);
}

export async function blockUser(id: String, token: string) {
  return await put(`/admin/action/user/block/${id}`, {}, token);
}

export async function unblockUser(id: String, token: string) {
  return await put(`/admin/action/user/unblock/${id}`, {}, token);
}

export async function createCert(id: String, token: string) {
  return await post(`/admin/action/options/cert/${id}`, {}, token);
}

export async function createUnit(id: String, token: string) {
  return await post(`/admin/action/options/unit/${id}`, {}, token);
}

export async function deleteUnit(id: String, token: string) {
  return await remove(`/admin/action/options/unit/${id}`, token);
}

export async function deleteCert(id: String, token: string) {
  return await remove(`/admin/action/options/cert/${id}`, token);
}

export async function updateRacByAdminId(
  adminId: String,
  id: String,
  data: any,
  token: string
) {
  return await put(`/admin/action/rac/update/${adminId}/${id}`, data, token);
}

export async function addUnitsToArray(id: String, token: string) {
  return await put(`/admin/action/rac/addunits/${id}`, {}, token);
}

export async function removeUnitsFromArray(id: String, token: string) {
  return await put(`/admin/action/rac/removeunits/${id}`, {}, token);
}

export async function addCertsToArray(id: String, token: string) {
  return await put(`/admin/action/rac/addcerts/${id}`, {}, token);
}

export async function removeCertsFromArray(id: String, token: string) {
  return await put(`/admin/action/rac/removecerts/${id}`, {}, token);
}

export async function removeAllCerts(id: String, token: string) {
  return await put(`/admin/action/rac/removeallcerts/${id}`, {}, token);
}

export async function removeAllUnits(id: String, token: string) {
  return await put(`/admin/action/rac/removeallunits/${id}`, {}, token);
}
