import { IonContent, IonPage } from "@ionic/react";
import { ReactNode } from "react";

import Header from "./Header";

type Props = {
  children: ReactNode;
  title: string;
  withMenu?: boolean;
  withBackButton?: boolean;
};

function Page({
  children,
  title,
  withMenu = false,
  withBackButton = false,
}: Props) {
  return (
    <IonPage>
      <Header
        title={title}
        withMenu={withMenu}
        withBackButton={withBackButton}
      />
      <IonContent>{children}</IonContent>
    </IonPage>
  );
}

export default Page;
