import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import { AppContext } from "../State";
import Menu from "../components/Menu";
import AdminOptions from "./AdminOptions";
import Admins from "./Admins";
import Profile from "./Profile";
import RACView from "./RACView";
import RACs from "./RACs";
import Search from "./Search";
import Users from "./Users";
import UsersWaitlist from "./UsersWaitlist";

function Main() {
  const { user } = useContext(AppContext);

  const isAdmin = user?.is_admin ?? false;

  return (
    <IonSplitPane contentId="main">
      <Menu />
      <IonRouterOutlet id="main">
        <Route path="/app/admins" exact>
          {isAdmin ? <Admins /> : <Redirect to="/search" />}
        </Route>
        <Route path="/app/racs" exact>
          {isAdmin ? <RACs /> : <Redirect to="/search" />}
        </Route>
        <Route path="/app/users" exact>
          {isAdmin ? <Users /> : <Redirect to="/search" />}
        </Route>
        <Route path="/app/waitlist" exact>
          {isAdmin ? <UsersWaitlist /> : <Redirect to="/search" />}
        </Route>
        <Route path="/app/options" exact>
          {isAdmin ? <AdminOptions /> : <Redirect to="/search" />}
        </Route>
        <Route path="/app/profile" exact>
          <Profile />
        </Route>
        <Route path="/app/rac/:racId" exact>
          <RACView />
        </Route>
        <Route path="/app/search" exact>
          <Search />
        </Route>
        <Route render={() => <Redirect to="/app/search" />} />
      </IonRouterOutlet>
    </IonSplitPane>
  );
}

export default Main;
