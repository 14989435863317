const apiUrl = "https://rac-app-api.azurewebsites.net/api";

export async function noTokenPost(url: String, data: any) {
  const response = await fetch(`${apiUrl}${url}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function post(url: String, data: any, token: string) {
  const response = await fetch(`${apiUrl}${url}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function formPost(url: String, data: FormData, token: string) {
  const response = await fetch(`${apiUrl}${url}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "x-access-token": token,
    },
    body: data,
  });
  return response.json();
}

export async function put(
  url: String,
  data: Record<string, any>,
  token: string
) {
  const res = await fetch(`${apiUrl}${url}`, {
    credentials: "same-origin",
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }
  return res.json();
}

export async function remove(url: String, token: string) {
  const response = await fetch(`${apiUrl}${url}`, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
  return response.json();
}

export async function get(url: String, token: string) {
  console.log(token);
  const res = await fetch(`${apiUrl}${url}`, {
    method: "GET",
    mode: "cors",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });

  console.log(res);
  if (!res.ok) {
    return { status: res.status, text: res.statusText };
  }
  return res.json();
}
