const apiUrl =
  "https://rg3ftkk6ic.execute-api.us-west-2.amazonaws.com/default/growers-edge-logger";

export function logError(message: String) {
  console.log(message);
  return post({
    error: message,
  });
}

export async function post(data: any) {
  const response = await fetch(`${apiUrl}`, {
    method: "POST",
    mode: "no-cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
}
