import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";

import { AppContext } from "../State";
import { getAllAdmins } from "../api/admin_api";
import Page from "../components/Page";

type Admin = {
  org: string;
  name: string;
  email: string;
};

function Admins() {
  const { user } = useContext(AppContext);
  const [admins, setAdmins] = useState([]);

  const userId = user!.id;
  const token = user!.token;

  useEffect(() => {
    const loadAdmins = async () => {
      const a = await getAllAdmins(userId, token);
      setAdmins(a);
    };

    loadAdmins();
  }, [userId]);

  if (!!admins.length) {
    return (
      <Page title="Admins" withMenu>
        <IonList>
          {admins.map((admin: Admin) => (
            <IonCard key={admin.email}>
              <IonCardHeader>
                <IonCardSubtitle>{admin.org ?? ""}</IonCardSubtitle>
                <IonCardTitle>{admin.name}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <IonItem lines="none">
                  <IonLabel>Email: {admin.email}</IonLabel>
                </IonItem>
              </IonCardContent>
            </IonCard>
          ))}
        </IonList>
      </Page>
    );
  }

  return (
    <Page title="Admins" withMenu>
      <IonItem routerDirection="none" lines="none" detail={false}>
        <IonLabel>No admins to display.</IonLabel>
      </IonItem>
    </Page>
  );
}

export default Admins;
